.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.chatRoot {
    flex: 1;
    display: flex;
}

.chatContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.chatEmptyState {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 1024px;
    padding-top: 60px;
}

.chatEmptyStateTitle {
    font-size: 4rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 30px;
}

.chatEmptyStateSubtitle {
    font-weight: 600;
    margin-bottom: 10px;
}

.chatMessageStream {
    flex-grow: 1;
    max-height: 1024px;
    max-width: 1028px;
    width: 100%;
    overflow-y: auto;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
}

.chatMessageGpt {
    margin-bottom: 20px;
    max-width: 80%;
    display: flex;
    min-width: 500px;
}

.chatMessageGptMinWidth {
    max-width: 500px;
    margin-bottom: 20px;
}

.chatInput {
    position: sticky;
    bottom: 0;
    flex: 0 0 100px;
    padding-top: 12px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    max-width: 1028px;
    background: #f2f2f2;
}

.chatAnalysisPanelContainer {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 10;
    bottom: 140px;
    display: flex;
    align-items: center;
    justify-items: center;
}

.chatAnalysisPanel {
    flex: 1;
    overflow-y: auto;
    max-height: 89vh;
    max-width: 984px;
    height: 82vh;
    margin: 0 auto;
    background: white;
    padding: 24px;
    border-radius: 4px;
    box-shadow:
        0px 2px 4px rgba(0, 0, 0, 0.14),
        0px 0px 2px rgba(0, 0, 0, 0.12);
}

.chatSettingsSeparator {
    margin-top: 15px;
}

.loadingLogo {
    font-size: 28px;
}

.commandsContainer {
    display: flex;
    position: sticky;
    width: 100%;
    max-width: 1024px;
    height: 6vh;
    top: 0;
    right: 0;
    background: #f2f2f2;
    align-items: center;
    justify-content: end;
    z-index: 10;
    margin: 0 auto;
}

.commandButton {
    height: 100%;
}

@media only screen and (max-width: 780px) {
    .chatEmptyState {
        padding-top: 0;
    }

    .chatEmptyStateTitle {
        font-size: 2.5rem;
        margin-bottom: 0px;
    }

    .chatMessageGpt {
        margin-bottom: 20px;
        max-width: 100%;
        display: flex;
        min-width: 200px;
    }

    
}

@media screen and (max-width: 540px) {
    .chatAnalysisPanel {
        flex: 1;
        overflow-y: auto;
        max-height: 89vh;
        height: 84vh;
        margin-left: 20px;
        margin-right: 20px;
        background: white;
        padding: 24px 12px;
        border-radius: 4px;
    }

    .chatMessageStream {
        flex-grow: 1;
        /* max-height: 1024px; */
        max-width: 100%;
        width: 100%;
        overflow-y: auto;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        flex-direction: column;
    }
}
