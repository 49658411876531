.questionInputContainer {
    border-radius: 8px;
    box-shadow:
        0px 8px 16px rgba(0, 0, 0, 0.14),
        0px 0px 2px rgba(0, 0, 0, 0.12);
    height: 90px;
    width: 100%;
    padding: 15px;
    background: white;
}

.questionInputTextArea {
    width: 100%;
    line-height: 40px;
}

.questionInputButtonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
